import React from "react"
import { Container, Box } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import type { Props } from "@app/templates/generic"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"

const Generic: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)

  return (
    <>
      <Title title={page?.title} />
      <Container maxW="container.md" minH="calc(100vh - 531px)" mb={16}>
        <Box mt={4}>{content}</Box>
      </Container>
    </>
  )
}

export default Generic
